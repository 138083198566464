import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBhhAcj5OzHl9k4AsQ2QOnBPuYu10Lyy-o",
  authDomain: "hsawebsite-6d6f6.firebaseapp.com",
  projectId: "hsawebsite-6d6f6",
  storageBucket: "hsawebsite-6d6f6.appspot.com",
  messagingSenderId: "126981669383",
  appId: "1:126981669383:web:cd0c9356320912fe4da0a2"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();