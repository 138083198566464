import React from "react";
import { Card, Text, List } from "@mantine/core";
import "./RankCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
const RankCard = ({ title, description, responsibilities, requirements }) => {
  return (
    // This card can have a href tag and acts as a link
    <Card shadow="sm" p="xl" component="a" className="ranks__card">
      <Text weight={500} size="lg" className="ranks__card--title main__title ">
        {title}
      </Text>

      <Text weight={500} size="sm" className="ranks__card--title">
        Description:
        <div className="rank__points"> {description}</div>
      </Text>

      <Text weight={500} className="ranks__card--title">
        Responsibilities :
        <List className="rank__points">
          {responsibilities?.map((resp, index) => {
            return (
              <List.Item key={index}>
                <FontAwesomeIcon
                  icon={faCircle}
                  fontSize={"8px"}
                  className="card__circle"
                />
                {resp.responsibility}
              </List.Item>
            );
          })}
        </List>
      </Text>
      <Text weight={500} className="ranks__card--title">
        Requirements :
        <List className="rank__points">
          {requirements?.map((req, index) => {
            return (
              <List.Item key={index}>
                <FontAwesomeIcon
                  icon={faCircle}
                  fontSize={"8px"}
                  className="card__circle"
                />
                {req.requirement}
              </List.Item>
            );
          })}
        </List>
      </Text>
    </Card>
  );
};

export default RankCard;
