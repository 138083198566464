import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth } from "./Pages/AdminLogin/firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Home from "./Pages/Home/Home";
import Ranks from "./Pages/Ranks/Ranks";
import Departments from "./Pages/Departments/Departments";
import Rules from "./Pages/Rules/Rules";
import Gamingnews from "./Pages/Gamingnews";
import Navigation from "./Components/Navigation/Navigation";
import AdminLogin from "./Pages/AdminLogin/AdminLogin";
import AnnouncementPage from "./Pages/Announcements/AnnouncementPage";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);
  return (
    <div>
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/Ranks" element={<Ranks />} />
          <Route exact path="/Rules" element={<Rules />} />
          <Route exact path="/Departments" element={<Departments />} />
          <Route exact path="/Gamingnews" element={<Gamingnews />} />
          {!user ? (
            <Route exact path="/admin" element={<AdminLogin user={user} />} />
          ) : (
            <Route
              exact
              path="/announcement-page"
              element={<AnnouncementPage user={user} setUser={setUser} />}
            />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
