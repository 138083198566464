import React from "react";
import RankCard from "../../Components/Ranks/RankCard";
import './Ranks.css'
const Ranks = () => {

  const ranks = [
    {
      title: "|R0| Recruit",
      description: "An Initiate is a newly invited member of the Hard Style\
      Assassins community.",
      responsibilities: [
        {
          responsibility: "Activity within the HSA Community",
        }
      ],
      requirements: [
        {
          requirement: "Must have spent no less than 7 days as an initiate of HSA Gaming",
        }
      ]
    },
    {
      title: "|R1| Assassin",
      description: "Full member status is granted to initiates who have spent\
            no less than 7 days in HSa gaming.",
      responsibilities: [
        {
          responsibility: "Activity within the HSA Community",
        }
      ],
      requirements: [
        {
          requirement: " Must have spent no less than 7 days as an initiate of HSA Gaming",
        }]
    },
    {
      title: "|R2| Enforcer",
      description: "The title of Enforcer is granted to active members of HSA \
            Gaming who have gone the extra mile via activity, or the use of an “HSA”\
            Gamertag, and have begun showing interest in becoming more involved in\
            the HSA Gaming Clan Operations.",
      responsibilities: [
        {
          responsibility: "Activity within the HSA Community",


        },
        {
          responsibility: "Recruiting new members into HSA Gaming",
        }
        ,
        {
          responsibility: "Attending weekly practices/meetings",
        }
      ],
      requirements: [
        {
          requirement: " Must have spent no less than 14 days as a Member of HSA Gaming, OR \
                change to a “HSA” Gamertag in lieu of this",
        },
        {
          requirement: "Must learn how to recruit",
        }
      ]
    },
    {
      title: "|R3| Guardian",
      description: "The title of Guardian is granted to a successful Assassin \
            in a given Squad who has shown both dedication and a keenness for Clan\
            Operations.Guardian is the beginning of “Officers”, so to speak, of\
            their Squads, and exist for the purpose of assisting, and training new\
          members.They report directly to the Squad Co / Squad Leader.",
      responsibilities: [
        {
          responsibility: "Activity within the HSA Community",
        },
        {
          responsibility: "Recruiting new members into HSA Gaming"
        },
        {
          responsibility: "Active role in recruiting to squads"
        },
        { responsibility: "Training new members in their squads" },
      ],
      requirements: [
        {
          requirement: " Willingness to take on minor administrative duties in your squads",
        }]
    },
    {
      title: "|R4| Commissioner",
      description: "The title of Commissioner is granted to a successful\
            Guardian that has been promoted to the spot by their Squad Leader.They\
            report directly to the Squad Leader/ Co Squad Leader.",
      responsibilities:
        [
          {
            responsibility: "Activity within the HSA Community",
          },
          { responsibility: "Active role in recruiting to squads" },
          { responsibility: "Recruiting new members into HSA Gaming" },
          { responsibility: "Assistance in squad operations such as organizing squad events" },],
      requirements: [
        {
          requirement: "Shown a willingness to lead and a knowledge of squad structuring",
        }
      ]
    },
    {
      title: "|R5| Co-Squad Leader",
      description: " Description: A Co Squad Leader are charged with assisting the squad\
          leader, acting as a moderator and administrator, and cooperating with\
          squad members to keep the squad active They will answer directly to a\
          Squad Leader.",
      responsibilities: [
        {
          responsibility: "Organizing and managing squads",
        },
        {
          responsibility: "Setting rules for your squads, so long as they apply with HSA\
              standards and rules",
        },
        {
          responsibility: "Taking care of internal affairs, such as promotions and disciplin",
        },
        {
          responsibility: "Recruiting new members into HSA Gaming",
        },
      ],
      requirements: [
        {
          requirement: "Extensive knowledge of clan structuring",
        },
        {
          requirement: "Active involvement in the growth of your squad",
        },
      ]
    },
    {
      title: "|R6| Squad Leader",
      description: " A Squad Leader oversees their Squad, acting as a moderator\
          and administrator, and co- operating with other squad leaders to keep\
          communities active and promote a sense of community.They will answer\
          directly to their division leader",
      responsibilities: [
        {
          responsibility: "Organizing and managing squads",
        },
        {
          responsibility: "Setting rules for your squads, so long as they apply with HSA\
              standards and rules",
        },
        {
          responsibility: "Taking care of internal affairs, such as promotions and discipline",
        },
        {
          responsibility: " Cooperation with other Squad Leaders to organize inter-squad events\
              and scrim matches",
        },
        {
          responsibility: "Recruiting new members into HSA Gaming",
        },
      ],
      requirements: [
        {
          requirement: "Extensive knowledge of clan structuring",
        },
        {
          requirement: "Active involvement in the growth of your squad",
        },
      ]
    },
    {
      title: "|R7| Co-Division Leader",
      description: "A Squad Leader is promoted to Co-Div when their squad\
          reaches the size limit of 25 members, the Squad Leader will promote\
          Commissioner, and Co- Squad Leader to take over the two new squads, and\
          the old squad leader will become one of other Co - Div's in their\
          division.",
      responsibilities: [
        {
          responsibility: " Oversee the Squad Leader and will be the Go-To for settling disputes\
              between squad leaders",
        },
        {
          responsibility: "Charge of communicating with the Division Leaders to alternate daily\
              duties",
        },
        {
          responsibility: "Be actively involved with the all the squads within the division",
        },
        {
          responsibility: "Recruiting new members into HSA Gaming",
        }
      ],
      requirements: [
        {
          requirement: "Squad growth that requires the Squad split into two separate squads",
        }
        ,
        {
          requirement: "Approval from the Division Leader and Elected Council",
        }
        ,
        {
          requirement: "Must be highly established and active in the clan",
        }
        ,
      ]
    },
    {

      title: "| R8 | Division Leader",
      description: "A Division Leader oversees the entire game division's \
          operations, and reports to the council with reports on their division.\
          Current Division Leader's will help create and customize squads under \
          their command to build up their division, Divisions are capped to 5\
          squads each.",
      responsibilities: [
        {
          responsibility: "Manage the affairs of the divisions",
        },
        {
          responsibility: "Impartially moderate disputes that may occur",
        },
        {
          responsibility: "Be active in your divisions",
        },
        {
          responsibility: "Recruiting new members into HSA Gaming",
        },
      ],
      requirements: [
        {
          requirement: "Be a successful Division Leader",
        },
        {
          requirement: "Creation of a new division",
        },
        {
          requirement: "Excellent management skills",
        }
      ]
    },
    {
      title: "|R9| Elected Council",
      description: "The Elected Council is made of six members of the server,\
          Squad Leader or above, or in HSA for at least 3 Months, who are elected\
          into office on January 2nd of each year.They oversee operations in the\
          entire clan and answer to the Senior Director.",
      responsibilities: [
        {
          responsibility: "Active participation in clan affairs",
        },
        {
          responsibility: "Vote on key issues of the clan",
        },
        {
          responsibility: "Act as a moderator in the Discord server",
        },
        {
          responsibility: "Organize server wide events",
        },
        {
          responsibility: "Assist with diplomatic tasks to other clans",
        },
      ],
      requirements: [
        {
          requirement: "Must be elected for a year term",
        },
        {
          requirement: "Must be a Squad Leader or higher to nominate yourself for a vote",
        },
      ]
    },
    {
      title: "|R10| Senior Director",
      description: "he Senior Director is the leader of the clan and in charge\
          of high management functions and administration.",
      responsibilities: [
        {
          responsibility: "Active involvement in running the clan",
        },
        {
          responsibility: "Daily activity on the server",
        },
        {
          responsibility: "Listen to all comments, questions, or complaints by the members of\
            the clans, and bring them to the Council to discuss",
        },
        {
          responsibility: "Diplomatic actions with other clans",
        },
        {
          responsibility: "Settle disputes between Division Leaders and Elected Council",
        },
      ],
      requirements: [
        {
          requirement: "Must be elected for a year term",
        },
        {
          requirement: "Must be a Squad Leader or higher to nominate yourself for a vote",
        },
        {
          requirement: "Must show a willingness to lead the clan",
        },
        {
          requirement: "Must agree to a peaceful transfer of power once your term is up",
        },
      ]
    },
  ];
  return (
    <section>
      <div className="container">
        <div className="row">
          <h2 className="pages__title">Hard Style Assassins Ranking Structure</h2>
          <div className="ranks__wrapper">
            {
              ranks.slice(0, 5).map((item, index) => {
                return (
                  <RankCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    requirements={item.requirements}
                    responsibilities={item.responsibilities}
                  />

                )
              })
            }
          </div>

          <h2 className="pages__title">High Command Ranks</h2>
          <div className="Line"></div>
          <div className="ranks__wrapper">
            {
              ranks.slice(5, 9).map((item, index) => {
                return (
                  <RankCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    requirements={item.requirements}
                    responsibilities={item.responsibilities}
                  />

                )
              })
            }
          </div>

          <h2 className="pages__title">Elected Leadership Ranks</h2>

          <div className="ranks__wrapper">
            {
              ranks.slice(9, 11).map((item, index) => {
                return (
                  <RankCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    requirements={item.requirements}
                    responsibilities={item.responsibilities}
                  />

                )
              })
            }
          </div>
        </div>
      </div>

    </section >
  );
};

export default Ranks;
