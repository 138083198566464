import React from "react";
import { Card, Text } from "@mantine/core";
import {
  faShield,
  faFillDrip,
  faPhotoFilm,
  faStream,
  faVideo,
  faSignal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DepartmentCard.css";
const DepartmentCard = ({ icon, title, description }) => {
  return (
    <Card shadow="sm" p="xl" component="a" className="dep__card">
      {icon === "faShield" && (
        <FontAwesomeIcon
          icon={faShield}
          fontSize={"30px"}
          className="dep__card--icon"
        />
      )}

      {icon === "faFillDrip" && (
        <FontAwesomeIcon
          icon={faFillDrip}
          fontSize={"30px"}
          className="dep__card--icon"
        />
      )}

      {icon === "faPhotoFilm" && (
        <FontAwesomeIcon
          icon={faPhotoFilm}
          fontSize={"30px"}
          className="dep__card--icon"
        />
      )}

      {icon === "faStream" && (
        <FontAwesomeIcon
          icon={faSignal}
          fontSize={"30px"}
          className="dep__card--icon"
        />
      )}

      {icon === "faVideo" && (
        <FontAwesomeIcon
          icon={faVideo}
          fontSize={"30px"}
          className="dep__card--icon"
        />
      )}

      <Text
        weight={500}
        size="lg"
        className="dep__card--title main__title--dep"
      >
        {title}
      </Text>

      <Text weight={500} size="sm" className="dep__card--title">
        Description:
        <div className="description__para"> {description}</div>
      </Text>
    </Card>
  );
};

export default DepartmentCard;
