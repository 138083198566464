import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose, faSortDown } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/logo.png";
import { useLocation } from "react-router-dom";
const Navigation = () => {
  const location = useLocation();
  let pathname = window.location.pathname;
  const [displayLinks, setDisplayLinks] = useState(false);
  const [displayLogo, setDisplayLogo] = useState(true);
  function openMenu() {
    document.body.classList += " menu--open";
  }

  function closeMenu() {
    document.body.classList.remove("menu--open");
  }
  useEffect(() => {
    location.key.match("default") || location.pathname.match("/Home")
      ? setDisplayLogo(false)
      : setDisplayLogo(true);
  }, [location]);
  return (
    //  regular nav
    <nav>
      <div
        className={`${
          location.key.match("default") || location.pathname.match("/Home")
            ? "nav__container nav__container--home"
            : "nav__container"
        }`}
      >
        {displayLogo && (
          <Link to="/">
            <img className="logo" src={Logo} alt="logo" />
          </Link>
        )}
        <ul className="nav__links">
          <li className={`${pathname.match("/Home") ? "link-active" : ""}`}>
            <Link
              to="/Home"
              className="nav__link link__hover-effect-blue link__blue
            link__hover-effect"
            >
              Home
            </Link>
          </li>

          <li className="nav__dropdown--link">
            <Link
              style={{ cursor: "not-allowed" }}
              to="#"
              className="nav__link link__hover-effect "
            >
              Member Manuals
            </Link>
            <ul className="nav__dropdown">
              <li
                className={`${pathname.match("/Ranks") ? "link-active" : ""}`}
              >
                <Link
                  to="/Ranks"
                  className="nav__link link__hover-effect dropdown--link"
                >
                  Ranks
                </Link>
              </li>
              <li
                className={`${pathname.match("/Rules") ? "link-active" : ""}`}
              >
                <Link
                  to="/Rules"
                  className="nav__link link__hover-effect dropdown--link"
                >
                  Rules and Regulations
                </Link>
              </li>
              <li
                className={`${
                  pathname.match("/Departments") ? "link-active" : ""
                }`}
              >
                <Link
                  to="/Departments"
                  className="nav__link link__hover-effect dropdown--link"
                >
                  Departments
                </Link>
              </li>
            </ul>
          </li>

          {/* <li
            className={`${pathname.match("/Gamingnews") ? "link-active" : ""}`}
          >
            <Link to="/Gamingnews" className="nav__link link__hover-effect">
              Gaming News
            </Link>
          </li> */}
          <li>
            <a
              className="nav__link link__hover-effect link__blue link__hover-effect-blue"
              href="https://hsa-donate.myspreadshop.com/"
            >
              Merchandise
            </a>
          </li>
          {/* <li>
            <a
              className="nav__link link__hover-effect"
              href="https://hsagaming.cms.gg/index.php?db=login&redirect=%2F"
            >
              CMS
            </a>
          </li> */}
        </ul>

        {/* mobile nav */}

        <button
          className={`${
            location.key.match("default") || location.pathname.match("/Home")
              ? "btn__menu btn-home"
              : "btn__menu"
          }`}
          onClick={() => openMenu()}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className="menu__backdrop">
          <button
            className="btn__menu btn__menu--close"
            onClick={() => closeMenu()}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>

          <ul className="menu__links">
            <li
              className={`${
                pathname.match("/Home")
                  ? "link-active menu__list"
                  : "menu__list"
              }`}
            >
              <Link
                to="/Home"
                className="menu__link link__blue
                "
                onClick={() => closeMenu()}
              >
                Home
              </Link>
            </li>

            <li className="menu__dropdown--wrapper menu__list">
              <div className=" menu__dropdown--link">
                <Link to="#" className="menu__link link__hover-effect-blue  ">
                  Member Manuals
                </Link>
                <FontAwesomeIcon
                  className="menu__dropdown--icon"
                  icon={faSortDown}
                  onClick={() => {
                    !displayLinks
                      ? setDisplayLinks(!displayLinks)
                      : setDisplayLinks(!displayLinks);
                  }}
                />
              </div>

              {displayLinks && (
                <ul className="menu__dropdown" id="dropdown">
                  <li
                    className={`${
                      pathname.match("/Ranks")
                        ? "link-active  dropdown--link--menu"
                        : " dropdown--link--menu"
                    }`}
                  >
                    <Link
                      to="/Ranks"
                      className="menu__link  dropdown--link"
                      onClick={() => {
                        closeMenu();
                        setDisplayLinks(!displayLinks);
                      }}
                    >
                      Ranks
                    </Link>
                  </li>
                  <li
                    className={`${
                      pathname.match("/Rules")
                        ? "link-active dropdown--link--menu"
                        : "dropdown--link--menu"
                    }`}
                  >
                    <Link
                      to="/Rules"
                      className="menu__link  dropdown--link"
                      onClick={() => {
                        closeMenu();
                        setDisplayLinks(!displayLinks);
                      }}
                    >
                      Rules and Regulations
                    </Link>
                  </li>
                  <li
                    className={`${
                      pathname.match("/Departments")
                        ? "link-active dropdown--link--menu"
                        : "dropdown--link--menu"
                    }`}
                  >
                    <Link
                      to="/Departments"
                      className="menu__link  dropdown--link"
                      onClick={() => {
                        closeMenu();
                        setDisplayLinks(!displayLinks);
                      }}
                    >
                      Departments
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* <li
              className={`${
                pathname.match("/Gamingnews")
                  ? "link-active menu__list"
                  : "menu__list"
              }`}
            >
              <Link
                to="/Gamingnews"
                className="menu__link"
                onClick={() => closeMenu()}
              >
                Gaming News
              </Link>
            </li> */}
            <li className="menu__list link__hover-effect-blue link__blue">
              <a
                className="menu__link link__hover-effect-blue link__blue "
                href="https://hsa-donate.myspreadshop.com/"
                onClick={() => closeMenu()}
              >
                Merchandise
              </a>
            </li>
            {/* <li className="menu__list">
              <a
                className="menu__link "
                href="https://hsagaming.cms.gg/index.php?db=login&redirect=%2F"
                onClick={() => closeMenu()}
              >
                CMS
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
