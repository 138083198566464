import React from "react";
import DepartmentCard from "../../Components/Departments/DepartmentCard";
import "./Departments.css";

const Departments = () => {
  const departments = [
    {
      icon: "faShield",
      title: "|GIS| Security Department",
      description:
        " For your security HSA members are protected by internal agents that\
              work within this department to handle all Internal Affairs matters,\
      including ongoing investigations into rules being broken.They work on\
              protecting all members in the server from malicious foreign entities.",
    },
    {
      icon: "faFillDrip",
      title: "|GFX| Graphic Design",
      description:
        "  HSA Clan ops, includes a full team of Graphic Design Artists that work\
              proactively on projects to better the creativity of our community,\
      including that of logos, banners, and artwork.",
    },
    {
      icon: "faPhotoFilm",
      title: "|VFX| Video Editing",
      description:
        " When videos need to be made, whether it be promotional material or\
              showcasing our comp teams, these will be the people to go to for all\
              your video content, using programs like Vegas Pro or Camtasia Studio\
              is a breeze for our VFX department, they work on projects proactively\
              by request of High Command.",
    },
    {
      icon: "faStream",
      title: "|Streamers| Live Streaming and Commentary",
      description:
        "  Live Streamers are a integral part of our clan operations, whether \
                it’s commentary or streaming gaming content, they work in sync with\
                HSA events to help promote HSA related content and help us reach\
                larger audiences.",
    },
    {
      icon: "faVideo",
      title: "|Social Media Team| SocMed",
      description:
        "Our social media Team consists of those with previous Community\
                Moderation experience in game forums and the like.They manage our\
                connection to outsourcing and social media platforms.",
    },
  ];
  return (
    <section>
      <div className="container">
        <div className="row">
          <h2 className="pages__title">Clan Operation Departments</h2>
          <div className="departments__wrapper">
            {departments.map((item, index) => {
              return (
                <DepartmentCard
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Departments;
