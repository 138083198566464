import React from "react";
import './Rules.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faA, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
const Rules = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="rules__wrapper">
            <h1 className="pages__title">Rules and Regulations</h1>
            <div>
              <h3 className="section__title">Section 1: Be Excellent To One Another</h3>
              <ul className="rules__list--wrapper">
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Harassment and attacking other members is not tolerated. It is
                  unacceptable to attack a person or a community based on attributes
                  such as their race, ethnicity, national origin, sex, gender, sexual
                  orientation, religious affiliation, or disabilities. We understand
                  that this is a gaming server and sometimes people get mad. We
                  understand that friendly banter is a thing, and at one point or
                  another we've all said things that would get us banned off of social
                  media. However, attacking members of the server will result in an
                  immediate mute for a day on the 1st offense, week on the 2nd, and
                  server ban on the 3rd. Keep your gamer words off of #General, we
                  have #Spicy General for that. The High Council does have brains, and
                  the ability to tell if you are being an ass or just joking around
                  with a friend. Is your friend acting a fool, and you tell him to
                  quit being gay? Or are you being hateful towards a member of the
                  server that is gay. We can tell the difference.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Do not make threats of violence or threaten to harm others. This
                  includes indirect threats, as well as sharing or threatening to
                  share someone’s private personal information (also known as
                  doxxing). This is a crime and is taken seriously. It will result in
                  an immediate ban from the clan, and your accounts will be placed on
                  a blacklist that will be spread amongst allied clans.
                </li>
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Do not send others viruses or malware. Do not attempt to phish
                  others, or hack or DDoS them. Once more this is punishable by an
                  immediate ban and subsequent addition to the blacklist.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  You may not promote, distribute, or provide access to content
                  involving the hacking, cracking, or distribution of pirated software
                  or stolen accounts. This includes sharing or selling cheats,
                  accounts, or hacks that may negatively affect others in multiplayer
                  games. This will also result in a ban and blacklist. We have
                  integrity on this server.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  You may not share content that violates anyone's intellectual
                  property or other rights in an attempt to pass them off as your own.
                  Find a gfx you think it's cool? Share it with everyone else on the
                  server and say this is the guy that made it. Try to pass it off as
                  your own? 1st offense is a one week ban, 2nd is a month, 3rd is half
                  a year. If you break this rule more than three times you will be
                  banned permanently.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  We do not tolerate disrespect, whether it is against the team or a
                  community member. There is a difference between constructive
                  criticism and disrespect. Constructive criticism is for helping
                  others, and disrespect is for hurting others intentionally.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Use the channels correctly. Promotion outside of #links-music is
                  prohibited. Cross-posting or spam is also prohibited. Spam will
                  result in an immediate one hour mute on the first offense, one day
                  on the 2nd and month on the 3rd.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Pornographic material is not allowed on the server, you have your
                  own incognito browsers for that.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  HSA Gaming reserves the right to change these rules at any time. Age
                  of entrance into the clan is 18, you should mature enough to act
                  like an adult.
                </li>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Must be 16+ years or older to be part of HSA
                </li>


                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  HSA United is a TOXIC FREE environment, toxicity will result in a
                  immediate warning
                </li>
              </ul>

              <h3 className="section__title">Section 2: Election Regulations and Rules</h3>
              <div className="rules__para">
                HSA elects it’s 6 Council Members January of every year, every Council
                member has a term of 1 year and can run for re-election, once elected,
                the Council Elects its new Director. In the event a member of the
                Council hands in a resignation, a special election will be held, and a
                new council member shall be voted in to take their place. The only
                exception is in the event of a declared emergency, resulting from a
                lack of members.
              </div>
              <ul className="rules__list--wrapper">
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  You must be an HSA member for at least a year to declare candidacy
                  and vote for the council election.
                </li>
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  You must declare candidacy to be placed into the running for
                  council.
                </li>
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  If the incumbent Director is replaced by the council, they must
                  prepare for an at ease transfer of power and ownership of the
                  server, it is vital as we keep the balance of power intact and do
                  what’s best for the Server and community. The elected council will
                  ensure the transfer goes smoothly.
                </li>
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  There are no term limits, and someone can run for Council and be
                  chosen as Director as many times as they want.
                </li>
              </ul>
              <h3 className="section__title">Section 3: Additional Rules</h3>
              <ul className="rules__list--wrapper">
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Any evidence of scamming, or any illegal activities will result in
                  an immediate 2 Strike.
                </li>
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  All HSA members who have been in our community for 3 months or more
                  are now allowed to initiate community wide changes through
                  “Referendums” which can be initiated in 🧿elections by typing pm!new
                  you can choose to keep the votes anonymous or not, the choice is
                  yours. Examples of what a Referendum should be:
                </li>
                <ul className="sub--list">
                  <li className="rule__list--point rule__list--point-sub">
                    <FontAwesomeIcon icon={faAnglesRight} className="list__icon sub--icon" />
                    Changes to rules and or structure.
                  </li>
                  <li className="rule__list--point rule__list--point-sub">
                    <FontAwesomeIcon icon={faAnglesRight} className="list__icon sub--icon" />
                    Immediate Replacement/Election of New Council/Can Be Specific
                    Members or Senior Director.
                  </li>
                </ul>

                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  Diplomatic Stances toward other communities Examples of topics to
                  NOT Initiate Referendums are:
                </li>
                <ul className="sub--list">
                  <li className="rule__list--point rule__list--point-sub">
                    <FontAwesomeIcon icon={faAnglesRight} className="list__icon sub--icon" />
                    Adding/Deleting Channels
                  </li>
                  <li className="rule__list--point rule__list--point-sub">
                    <FontAwesomeIcon icon={faAnglesRight} className="list__icon sub--icon" />
                    Anything that can be implemented through 🪧suggestions, in the
                    Discord server
                  </li>
                  <li className="rule__list--point rule__list--point-sub">
                    <FontAwesomeIcon icon={faAnglesRight} className="list__icon sub--icon" />
                    Kicking out or Banning members (This can be solved by going to
                    ❔disputes) in the Discord server
                  </li>
                </ul>
                <b className="rules__para">
                  ALL REFERENDUMS WILL BE ENACTED BY LEADERSHIP REGARDLESS OF THE
                  REQUEST AS LONG IT MEETS CRITERIA
                </b>
              </ul>
              <h3 className="section__title">Section 4: Additional Leadership Rules</h3>
              <ul className="rules__list--wrapper">
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  When council is not filled, Senior Director has Emergency powers to
                  appoint those seats, if the council is filled, the only way to
                  remove a member from council is through a vote initiated by someone
                  on the council or the senior director otherwise a filled council
                  should serve a full year term and can run for re-election.
                </li>
                <li className="rule__list--point">
                  <FontAwesomeIcon icon={faAnglesRight} className="list__icon" />
                  If the Senior Director were to step down, the council will have to
                  convene and vote on a new Senior Director.
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </section>
  );
};

export default Rules;
