import React, { useState, useEffect } from "react";
import { FaDiscord, FaTwitter, FaYoutube } from "react-icons/fa";
import { onSnapshot } from "firebase/firestore";
import { query, orderBy } from "firebase/firestore";
import "./Home.css";
import { db } from "../AdminLogin/firebase.js";
import { collection } from "firebase/firestore";
import Logo from "../../assets/logo.png";
import { Timeline } from "react-twitter-widgets";

const Home = () => {
  // const [docs, setDocs] = useState([]);

  // async function getAllAnnouncements() {
  //   const q = query(
  //     collection(db, "announcements"),
  //     orderBy("timeStamp", "desc")
  //   );
  //   onSnapshot(q, (snapshot) =>
  //     setDocs(snapshot.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
  //   );
  // }
  // useEffect(() => {
  //   getAllAnnouncements();
  // }, []);

  return (
    <>
      <header className="header">
        <div className="header__content">
          <img className="logo__header" src={Logo} alt="" />
          <div className="title">Hard Style Assassins</div>
          <div className="social__list">
            <a
              href="https://discord.gg/s2sm2X8RV7"
              className="social__link click"
            >
              <FaDiscord />
            </a>
            <a
              href="https://youtube.com/channel/UCp5sOXsWo95WP8n7hYkk-iw"
              className="social__link click"
            >
              <FaYoutube />
            </a>
            <a
              href="https://twitter.com/gaming_hsa"
              className="social__link click"
            >
              <FaTwitter />
            </a>
          </div>

          
        </div>

        
        <div className="lines__container">
          <div id="lineh1"></div>
          <div id="lineh2"></div>
          <div id="lineh3"></div>
          <div id="lineh4"></div>
          <div id="lineh5"></div>
          <div id="lineh6"></div>
          <div id="lineh7"></div>
          <div id="lineh8"></div>
          <div id="lineh9"></div>
          <div id="lineh10"></div>
          <div id="lineh11"></div>
          <div id="lineh12"></div>
          <div id="lineh13"></div>
          <div id="lineh14"></div>
          <div id="lineh15"></div>
          <div id="lineh16"></div>
        </div>

        
      </header>

      <div className="twitter__container">
          <Timeline
            dataSource={{
              sourceType: "profile",
              screenName: "gaming_hsa",
            }}
            options={{
              height: "510",
              width: "350",
              theme: "dark"
            }}
          />
        </div>

      <div className="announcement__container">
        
        {/* {docs.length ? (
          docs.map((doc, index) => (
            <div key={index} className="announcement__wrapper">
              <div className="announcement__title-and-description">
                <h1 className="announcement__title">{doc.title}</h1>
                <p className="announcement__description">{doc.description}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="announcement__container--skeleton">
            <div className="announcement__wrapper--skeleton">
              <div className="announcement__title--skeleton"></div>
              <div className="announcement__para--skeleton"></div>
              <div className="announcement__button--skeleton"></div>
            </div>
          </div>
        )} */}
      </div>
      
    </>
  );
};
export default Home;
