import { auth, db } from "../AdminLogin/firebase.js";
import {
  collection,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { query, orderBy } from "firebase/firestore";

import "../Announcements/AnnouncementPage.css";

function Announcement({ setUser }) {
  const navigate = useNavigate();
  const [docs, setDocs] = useState([]);
  const announcementTitleRef = useRef("");
  const announcementDescriptionRef = useRef("");
  async function createAnnouncement() {
    if (
      !announcementTitleRef.current.value &&
      !announcementDescriptionRef.current.value
    ) {
      return;
    }

    addDoc(collection(db, "announcements"), {
      title: announcementTitleRef.current.value,
      description: announcementDescriptionRef.current.value,
      timeStamp: serverTimestamp(),
    });
  }

  async function getAllAnnouncements() {
    const q = query(
      collection(db, "announcements"),
      orderBy("timeStamp", "desc")
    );
    onSnapshot(q, (snapshot) =>
      setDocs(snapshot.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
    );
  }

  useEffect(() => {
    getAllAnnouncements();
  }, []);

  async function deleteAnnouncement(announcement) {
    const announcementRef = doc(db, "announcements", announcement);
    deleteDoc(announcementRef);
  }
  function logout() {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        throw new Error(error);
      });
    navigate("/");
  }

  return (
    <div className="app">
      <div className="announcement__input--wrapper">
        <input
          type="text"
          ref={announcementTitleRef}
          placeholder="Enter title"
          className="announcement__input--title"
        />
        <textarea
          type="text"
          ref={announcementDescriptionRef}
          className="announcement__input--description"
        ></textarea>
        <button
          onClick={() => createAnnouncement()}
          className="create__announcement"
        >
          Create Announcement
        </button>
      </div>
      <div className="announcement__container">
        {docs.length ? (
          docs.map((doc, index) => (
            <div key={index} className="announcement__wrapper">
              <div className="announcement__title-and-description">
                <h1 className="announcement__title">{doc.title}</h1>
                <p className="announcement__description">{doc.description}</p>
              </div>
              <button
                className="announcement__delete"
                onClick={() => deleteAnnouncement(doc.id)}
              >
                Delete announcement
              </button>
            </div>
          ))
        ) : (
          <div className="announcement__container--skeleton">
            <div className="announcement__wrapper--skeleton">
              <div className="announcement__title--skeleton"></div>
              <div className="announcement__para--skeleton"></div>
              <div className="announcement__button--skeleton"></div>
            </div>
          </div>
        )}
        <button onClick={() => logout()} className="logout__btn">
          Log Out
        </button>
      </div>
    </div>
  );
}

export default Announcement;
