import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../AdminLogin/firebase.js";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import "../AdminLogin/AdminLogin.css";

function AdminLogin() {
  const loginTitle = document.querySelector(".login__title");
  const [, setUser] = useState({});
  const navigate = useNavigate();
  const userEmail = useRef("");
  const userPassword = useRef("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);
  async function userLogin(e) {
    e.preventDefault();

    // HERE TO CREATE YOUR EMAIL AND PASSWORD
    // createUserWithEmailAndPassword(auth, userEmail.current.value, userPassword.current.value)
    //   .then((user) => {
    //     console.log(user);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    if (!userEmail.current.value && !userPassword.current.value) {
      return;
    }

    signInWithEmailAndPassword(
      auth,
      userEmail.current.value,
      userPassword.current.value
    )
      .then(({ user }) => {
        setUser(user);
        navigate("/announcement-page");
      })
      .catch(
        () =>
          (loginTitle.innerHTML =
            "The email or password is invalid. Please try again.")
      );
  }

  return (
    <form onSubmit={(e) => userLogin(e)}>
      <div className="login__wrapper">
        <h1 className="login__title">Please log in with email and password.</h1>
        <input
          type="email"
          ref={userEmail}
          placeholder="Enter email"
          className="input__email"
        />
        <input
          type="password"
          ref={userPassword}
          placeholder="Enter password"
          className="input__password"
        />
        <button onClick={() => userLogin()} className="admin__login--btn">
          Login
        </button>
      </div>
    </form>
  );
}

export default AdminLogin;
